import React from "react"
import { Link } from "gatsby"

const ProjectGrid = () => {
  return (
    <div className="mb-24">
      <div className="lg:grid lg:grid-rows-2 justify-items-center lg:gap-10">
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 mb-40 lg:mb-0 xl:mx-10 xl:px-40 lg:my-20">
          <div className="grid justify-items-center">
            <img src="/cp-site.png" height="auto" width="600" alt="hero" />
          </div>
          <div className="mt-10 md:mt-40 lg:ml-10 px-4 lg:px-0 md:text-center lg:text-left md:grid md:justify-items-center lg:justify-items-start">
            <h1 className="text-3xl md:text-4xl max-w-lg text-customgray leading-relaxed font-bold">
              Concreto Plus Inc.
            </h1>
            <h3 className="md:text-lg mt-2 max-w-xl text-customgray">
              A metropolitan concrete and masonry company that targets both
              residential homes and commercial buildings. Marsandi not only
              helped Concreto Plus redesign their website, but also assisted in
              building a digital brand through social media, web advertising,
              and more. When Concreto Plus decided to expand into other home
              services, Marsandi was ready to help.
            </h3>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 my-40 lg:mb-0 xl:mx-10 xl:px-40 lg:my-20">
          <div className="grid justify-items-center">
            <img src="/ge-site.png" height="auto" width="600" alt="hero" />
          </div>
          <div>
            <div className="mt-10 md:mt-40 lg:ml-10 px-4 lg:px-0 md:text-center lg:text-left md:grid md:justify-items-center lg:justify-items-start">
              <h1 className="text-3xl md:text-4xl max-w-lg text-customgray leading-relaxed font-bold">
                G&E Tax Financial Services
              </h1>
              <h3 className="md:text-lg mt-2 max-w-xl text-customgray">
                A 20+ year old small business providing trustworthy tax and
                accounting services. G&E decided to pivot from a local word of
                mouth business into building an online brand and take advantage
                of the new digital resources to help the company grow. Marsandi
                helped redesign their logo, website, and business cards. We also
                assisted in building a digital brand through social media, web
                advertising, and even provide guidance on security measures
                handling sensitive financial documents.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="text-customgray text-center grid justify-items-center align-middle my-40">
        <div className="w-11/12 md:w-6/12">
          <h1 className="text-3xl md:text-4xl text-customgray font-bold">
            Get Started Today
          </h1>
          <h3 className="md:text-xl mt-5 text-customgray">
            Running your business is already enough on your plate. Let us help
            you find those areas that can be improved to make your day to day
            tasks easier.
          </h3>
          <Link
            to="/services"
            className="inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-5"
          >
            See Our Services
          </Link>
        </div>
      </div>
      <div className="lg:grid lg:grid-rows-2 justify-items-center lg:gap-10">
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 mb-40 lg:mb-0 xl:mx-10 xl:px-40 lg:my-20">
          <div className="grid justify-items-center">
            <img src="/ar-site.png" height="auto" width="600" alt="hero" />
          </div>
          <div>
            <div className="mt-10 md:mt-40 lg:ml-10 px-4 lg:px-0 md:text-center lg:text-left md:grid md:justify-items-center lg:justify-items-start">
              <h1 className="text-3xl md:text-4xl max-w-lg text-customgray leading-relaxed font-bold">
                Alpha Remodeling Inc.
              </h1>
              <h3 className="md:text-lg mt-2 max-w-xl text-customgray">
                A fast growing construction and home remodeling company that
                targets both residential homes and commercial buildings. As a
                new company, Marsandi helped build their first website and
                created an online presence through business outlets,
                advertising, social media, email marketing, and more. Their
                project related online forms allow for quick and easy
                consultations to acquire new jobs.
              </h3>
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 my-40 lg:mb-0 xl:mx-10 xl:px-40 lg:my-20">
          <div className="grid justify-items-center">
            <img src="/nv-site.png" height="auto" width="600" alt="hero" />
          </div>
          <div>
            <div className="mt-10 md:mt-40 lg:ml-10 px-4 lg:px-0 md:text-center lg:text-left md:grid md:justify-items-center lg:justify-items-start">
              <h1 className="text-3xl md:text-4xl max-w-lg text-customgray leading-relaxed font-bold">
                Non Profit: New Life Church
              </h1>
              <h3 className="md:text-lg mt-2 max-w-xl text-customgray">
                A local non profit international church serving a multi-lingual
                audience. Marsandi helped create a logo, build a website, add
                social media marketing, and set up an online payment portal for
                online donations. Having an updated website provides members an
                easy way to view weekly events and be alerted of any news
                updates. Since Marsandi stepped in to help, New Life Church has
                now expanded by over 400% in regular attendance.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="text-customgray text-center grid justify-items-center align-middle my-40">
        <div className="w-11/12 md:w-6/12">
          <h1 className="text-3xl md:text-4xl text-customgray font-bold">
            Your Success Is Our Success
          </h1>
          <h3 className="md:text-xl mt-5 text-customgray">
            No matter the industry you're in, whether you're a small business or
            creating a product, we can help you reach your goals through
            branding, developing rich content sites, and digital marketing.
          </h3>
          <Link
            to="/services"
            className="inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-5"
          >
            See Our Services
          </Link>
        </div>
      </div>
      <div className="lg:grid lg:grid-rows-1 justify-items-center lg:gap-10">
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 mb-20 lg:mb-0 xl:mx-10 xl:px-40 lg:my-20">
          <div className="grid justify-items-center">
            <img src="/gi-site.png" height="auto" width="600" alt="hero" />
          </div>
          <div>
            <div className="mt-10 md:mt-40 lg:ml-10 px-4 lg:px-0 md:text-center lg:text-left md:grid md:justify-items-center lg:justify-items-start">
              <h1 className="text-3xl md:text-4xl max-w-lg text-customgray leading-relaxed font-bold">
                Non Profit: God's Impact
              </h1>
              <h3 className="md:text-lg mt-2 max-w-xl text-customgray">
                A local non profit church whose primary goal is to envangelize
                in different parts of North and South America. Since they don't
                have a brick and mortar location, an online brand and presence
                is key for their awareness and communication. Marsandi was
                tasked to build a website and set up an online payment portal
                for online donations. Today, God's Impact is doing charity work
                and natural disaster assistance in central american countries
                for the less fortunate.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectGrid
